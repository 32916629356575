import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { Button } from "./ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "./ui/drawer";
import { Card, CardContent } from "./ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel";

import "./mintpage.css";
import "./globals.css";
import Autoplay from "embla-carousel-autoplay";
const CONTRACT_ABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "initURI",
        type: "string",
      },
      {
        internalType: "address",
        name: "initialOwner",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "AddressInsufficientBalance",
    type: "error",
  },
  {
    inputs: [],
    name: "ApprovalCallerNotOwnerNorApproved",
    type: "error",
  },
  {
    inputs: [],
    name: "ApprovalQueryForNonexistentToken",
    type: "error",
  },
  {
    inputs: [],
    name: "BalanceQueryForZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "FailedInnerCall",
    type: "error",
  },
  {
    inputs: [],
    name: "MintERC2309QuantityExceedsLimit",
    type: "error",
  },
  {
    inputs: [],
    name: "MintToZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "MintZeroQuantity",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "OwnableInvalidOwner",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "OwnableUnauthorizedAccount",
    type: "error",
  },
  {
    inputs: [],
    name: "OwnerQueryForNonexistentToken",
    type: "error",
  },
  {
    inputs: [],
    name: "OwnershipNotInitializedForExtraData",
    type: "error",
  },
  {
    inputs: [],
    name: "ReentrancyGuardReentrantCall",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferCallerNotOwnerNorApproved",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferFromIncorrectOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferToNonERC721ReceiverImplementer",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferToZeroAddress",
    type: "error",
  },
  {
    inputs: [],
    name: "URIQueryForNonexistentToken",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "fromTokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "toTokenId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
    ],
    name: "ConsecutiveTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "PaymentReleased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "PRICE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PreMaxMint",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "PublicMaxMint",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "_merkleRoot",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimAllGas",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "claimAllYield",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "claimMaxGas",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "claimYield",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getMerkleRoot",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "maxTotalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "num",
        type: "uint256",
      },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "numberMinted",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "bytes32[]",
        name: "merkleProof",
        type: "bytes32[]",
      },
    ],
    name: "preSaleMint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "publicNumberMinted",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "release",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "newMarkleRoot",
        type: "bytes32",
      },
    ],
    name: "setMerkleRoot",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum TESTONLY.Status",
        name: "_status",
        type: "uint8",
      },
    ],
    name: "setStatus",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "newuri",
        type: "string",
      },
    ],
    name: "setURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "status",
    outputs: [
      {
        internalType: "enum TESTONLY.Status",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
]; // Your Contract ABI
const CONTRACT_ADDRESS = "0x9405E721D403472af125897DC40FCe51E7384582"; // Your Contract Address

const BLAST_SEPOLIA_DETAILS = {
  chainId: "0xA0C71FD", // Hexadecimal chain ID for Blast Sepolia (168587773 in hex)
  chainName: "Blast Sepolia",
  nativeCurrency: {
    name: "Sepolia Ether",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://sepolia.blast.io"], // Replace with actual RPC URL
  blockExplorerUrls: ["https://testnet.blastscan.io"], // Replace with actual Block Explorer URL
};

function MintPage() {
  const [account, setAccount] = useState("");
  const [contract, setContract] = useState(null);

  // Function to connect to Metamask

  const suggestNetworkChange = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: BLAST_SEPOLIA_DETAILS.chainId }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [BLAST_SEPOLIA_DETAILS],
          });
        } catch (addError) {
          console.error("Error adding new network:", addError);
        }
      } else {
        console.error("Error switching network:", switchError);
      }
    }
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);

        // Create contract instance
        const contractInstance = new web3.eth.Contract(
          CONTRACT_ABI,
          CONTRACT_ADDRESS
        );
        setContract(contractInstance);

        // Suggest network change
        await suggestNetworkChange();
      } catch (error) {
        console.error("Error connecting to Metamask:", error);
      }
    } else {
      alert("Please install Metamask!");
    }
  };

  const interactWithContract = async (payableAmountInEth, num) => {
    if (contract && account) {
      try {
        // Replace 'yourMethodName' with your contract's method name
        const payableAmountInWei = Web3.utils.toWei(
          payableAmountInEth.toString(),
          "ether"
        );

        // Replace 'yourMethodName' with your contract's method name
        const response = await contract.methods.mint(num).send({
          from: account,
          value: payableAmountInWei,
        });

        console.log("Transaction response:", response);
      } catch (error) {
        console.error("Error interacting with contract:", error);
      }
    } else {
      console.log("Contract not loaded or wallet not connected");
    }
  };
  // Check if Metamask is installed when the component mounts
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        setAccount(accounts[0]);
      });
    }
  }, []);

  return (
    <div className="app">
      <div class="back-ground-page">
        <header className="relative-header">
          <a href="/" className="logo-picture">
            pepelogo
          </a>
        </header>

        <div className="relative z-20 h-screen w-screen pt-[20px] pb-[180px] overflow-y-auto">
          <div className="w-full h-full px-[12px] sm:px-0 sm:w-[80%] lg:w-[65%] xl:w-[1200px] mx-auto ">
            <div className="lg:mt-4 h-full">
              <div className="flex flex-col xl:flex-row h-full">
                <div className="myHeight w-full xl:w-[760px] xl:h-[590px] py-[20px] px-[12px] xl:p-[30px]  border-black border-[0px] bg-white shadow-lg">
                  <div className=" flex justify-center ">
                    <div className=" flex justify-center shrink-0 relative w-[250px] h-[250px] sm:w-[180px] sm:h-[180px] lg:w-[380px] lg:h-[380px] xl:w-[550px] xl:h-[550px] rounded-[16px] ">
                      <Carousel
                        plugins={[
                          Autoplay({
                            delay: 2000,
                          }),
                        ]}
                        className="w-full justify-center"
                      >
                        <CarouselContent>
                          {Array.from({ length: 10 }).map((_, index) => (
                            <CarouselItem key={index}>
                              <div className="justify-center">
                                <Card>
                                  <CardContent className="flex aspect-square items-center justify-center p-6 w-[250px] h-[250px] sm:w-[180px] sm:h-[180px] lg:w-[380px] lg:h-[380px] xl:w-[550px] xl:h-[550px]">
                                    {/* <span className="text-4xl font-semibold">
                                      {index + 1}
                                    </span> */}
                                    <img
                                      src={`/displayImage/${index + 1}.png`} // Construct the image source dynamically
                                      className="w-full h-full object-cover justify-center "
                                      alt="pepesDis"
                                    />
                                  </CardContent>
                                </Card>
                              </div>
                            </CarouselItem>
                          ))}
                        </CarouselContent>
                        <CarouselPrevious />
                        <CarouselNext />
                      </Carousel>
                    </div>

                    {/* <div class="mt-10 ml-8">
                      <h3 class=" text-[21px] leading-[21px] xl:text-[24px] font-bold xl:leading-[24px] mb-2">
                        Mint CS PEPE NFT
                      </h3>
                      <p class=" text-[16px] xl:text-[18px] font-medium xl:font-semibold leading-[24px] xl:leading-[30px]">
                        Total offering: 5000
                      </p>
                      <p class=" text-[16px] xl:text-[18px] font-medium xl:font-semibold leading-[24px] xl:leading-[30px]">
                        Price: 0.19527 ETH
                      </p>
                      <p class=" text-[16px] xl:text-[18px] font-medium xl:font-semibold leading-[24px] xl:leading-[30px]">
                        Max mint per address: 2
                      </p>
                    </div> */}
                  </div>

                  {/* <div class="bg-grey w-full mt-[20px] xl:my-[30px] py-[30px] px-[12px] xl:h-[124px] rounded-[12px] xl:rounded-[16px] flex flex-col lg:flex-row lg:justify-between items-center">
                    <div class=" text-[18px] leading-[24px] font-medium text-left xl:text-left mb-[20px] lg:mb-0 mx-[20px]">
                      Lets being a part of the CS Pepe NFT community!
                    </div>

                  </div> */}

                  {/* <div class=" hidden lg:block mt-[20px] xl:mt-0">
                    <p>
                      CSPEPE NFT Smart Contract:{" "}
                      <a
                        href="https://etherscan.io/address/0x129d453253dbCE5CF6997DB8Fb461B2A20Fd275F"
                        target="__blank"
                        class=" text-[rgba(59,132,255,1)] underline"
                      >
                        Etherscan Contract
                      </a>
                    </p>
                  </div> */}

                  <h3 class="mt-10 flex justify-center text-[21px] leading-[21px] xl:text-[24px] font-bold xl:leading-[24px] mb-2">
                    Mint CS PEPE NFT
                  </h3>
                  <div class="shrink-0 flex items-center justify-center ">
                    <div class="mt-5 w-32 h-[56px] rounded-[150px] xl:h-[64px] bg-darkgrey border-[2px] hover-btn-shadow flex justify-center items-center text-[21px] xl:text-[24px] leading-[21px] xl:leading-[24px] font-semibold min-w-[240px] text-white xl:min-w-[260px]">
                      {account ? (
                        account &&
                        contract && (
                          <>
                            <Drawer>
                              <DrawerTrigger asChild>
                                {/* Your Trigger content here */}
                                <button>Mint Now</button>
                              </DrawerTrigger>
                              <DrawerContent>
                                <div className="mx-auto w-full max-w-sm">
                                  <DrawerHeader>
                                    <DrawerTitle>Mint CS PEPE</DrawerTitle>
                                    <DrawerDescription>
                                      Price: 0.005 ETH
                                    </DrawerDescription>
                                  </DrawerHeader>
                                  <div>
                                    <img className="image-logossl" alt="" />
                                  </div>
                                  <DrawerFooter>
                                    <Button
                                      onClick={() =>
                                        interactWithContract("0.05", 1)
                                      }
                                    >
                                      Mint Now
                                    </Button>
                                    <DrawerClose asChild>
                                      <Button variant="outline">Cancel</Button>
                                    </DrawerClose>
                                  </DrawerFooter>
                                </div>
                              </DrawerContent>
                            </Drawer>
                          </>
                        )
                      ) : (
                        <button onClick={connectWallet}>Connect wallet</button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="sm:mt-[20px] xl:mt-0 xl:ml-[20px] flex-col-reverse sm:flex-col">
                  <div class=" w-full xl:w-[420px] sm:h-[268px] sm:p-[30px] border-black border-[0px] bg-white shadow-lg">
                    <h3 class=" text-white sm:text-black text-[21px] sm:text-[24px] font-bold leading-6 mb-[20px] mt-[30px] sm:mt-0">
                      What is CS PEPE?
                    </h3>
                    <div class="">
                      <div>
                        <p class="leading-[14px] mt-[45px] text-[12px] font-semibold ">
                          The CSPepe NFT is at the core of our GameFi and
                          GambleFi ecosystem. It serves as a key element in our
                          game & casino.
                        </p>
                        <p class="text-[36px] sm:mt-[35px] sm:text-[15px] font-bold leading-[30px]">
                          Make sure you have ETH on Blast L2
                        </p>
                        <a href="https://testnet.blastscan.io/">
                          <img
                            className="blast-logo-mint sm:mt-[15px]"
                            src="logo-glow.webp"
                            alt="blast-logo"
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className=" w-full mt-[20px] xl:w-[420px] h-[302px] sm:bg-white sm:p-[30px] border-black border-[0px] bg-white shadow-lg">
                    <h3 class=" text-white sm:text-black text-[21px] sm:text-[24px] font-bold leading-6 mb-[20px] mt-[30px] sm:mt-0">
                      Mint CS PEPE NFT
                    </h3>
                    <div>
                      <div class="flex justify-start mb-[15px]">
                        <div class=" relative w-[16px] h-[52px] "></div>
                        <div class=" ml-5 text-[18px] font-semibold leading-[24px] text-black/100">
                          <p>Total offering:</p>
                          <p>5000</p>
                        </div>
                      </div>
                      <div class="flex justify-start mb-[15px]">
                        <div class=" relative w-[16px] h-[52px] "></div>
                        <div class=" ml-5 text-[18px] font-semibold leading-[24px] text-black/100">
                          <p>Price:</p>
                          <p>0.19527 ETH</p>
                        </div>
                      </div>
                      <div class="flex justify-start">
                        <div class=" relative w-[16px] h-[52px] "></div>
                        <div class=" ml-5 text-[18px] font-semibold leading-[24px] text-black/100 ">
                          <p>Max mint per address:</p>
                          <p>2</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MintPage;
