import React from "react";
import "./App.css";
import "./globals.css";
import { Link } from "react-router-dom";
import 'animate.css';
// Example ABI and Contract Address (Replace with your contract's ABI & Address)

function MainPage() {
  return (
    <div className="App">
      <div className="cspepe2-background">
        <div class="micro-header">
          <div class="micro-header-contents">
            <div class="micro-header-left-empty"></div>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content*/}
            <a class="micro-header-logo" href="https://blast.io/en" />
            <div class="micro-header-right-empty"></div>

            <Link to="https://twitter.com/cspepeNFT">
            <div class="discord-logo-class">
              <img className="x-logo" src="discord-logo.svg" width={"23px"} height={"50px"} alt="discord"></img>
            </div>
            </Link>
            <Link to="https://twitter.com/cspepeNFT">
            <div class="x-logo-class">
              <img className="x-logo" src="x-logo.png" width={"23px"} height={"50px"} alt="X"></img>
            </div>
            </Link>
            <div class="micro-header-limit-test">
              <div className="microheader_Mint">
                <Link to="https://twitter.com/cspepeNFT">Minting Soon</Link>
              </div>
            </div>
          </div>
          {/* <div class="header-section"> */}
          {/* <div class="headerBG"></div> */}
          <div class="cs2people"> </div>
        </div>

        <div>
          <img className="fire-image animate__animated animate__fadeIn" src="fire-image.png" alt="fire-ime"></img>
          <img className="snipe-image animate__animated animate__fadeInDownBig animate__delay-1s" src="snipe.png" alt="snipe-ime"></img>
          <img className="blast-logo animate__animated animate__fadeIn animate__delay-2s" src="logo-glow.webp" alt="blast-logo" />
           <div class="pepepicture">
            {/* eslint-disable-next-line jsx-a11y/alt-text*/}
            <img class="picturepepe animate__animated animate__fadeIn" src="2pepe.png" />
          </div>
          <div class="intro-container">
            {/* eslint-disable-next-line jsx-a11y/alt-text*/}
            {/* <div class="intro-limit-test">
              <div class="limit-testBG"></div>
              <div class="limit-Label">Limit Mint</div>
            </div> */}
          </div>
        </div>
        <div className="container">
        <img className="gif-logo  animate__animated animate__fadeIn" src="gif.gif" alt="Description of GIF" />
        </div>
      </div>
      <div class="cspepe2-intro">
        <div>
        <img className="loot-image animate__animated animate__fadeIn" src="LootBox.png" alt="LootBox-ime"></img>
          <img className="sniper-image animate__animated animate__fadeInDownBig animate__delay-10s" src="sniperBox.png" alt="sniper-ime"></img>
          <img className="BoomBox-image animate__animated animate__fadeInDownBig animate__delay-10s" src="BoomBox.png" alt="boom-ime"></img>
          <img className="musicBox-image animate__animated animate__fadeIn animate__delay-10s" src="musicBox.png" alt="musicBox-logo" />
          <img className="wordBox-image animate__animated animate__fadeIn animate__delay-10s" src="wordBox.png" alt="musicBox-logo" />
        </div>

        <div class="intro-box1">
          <div class="intro-text">
            <div class="Minor"> Launching soon on</div>
            BLAST CHAIN
          </div>
          <div class="intro-desc"></div>
        </div>
        <br></br>
        <br></br>

        <div class="intro-box">
          <div class="intro-text">
            <div class="Minor"> WHAT IS CS PEPE</div>
          </div>
          <div class="intro-desc">
          The CSPepe NFT: At the core of our ecosystem is the CSPepe NFT. This digital asset serves as a key element in both our game and casino experiences. By owning CSPepe NFTs, users gain access to exclusive benefits and opportunities within our platform. Whether it's unlocking special features, gaining in-game advantages, or participating in unique events, the CSPepe NFT is designed to enhance the overall experience for our community.
          </div>
        </div>

        <div class="intro-box">
          <div class="intro-text">
            <div class="Minor"> GameFi</div>
          </div>
          <div class="intro-desc">
          Our dedicated team of developers is currently hard at work creating an on-chain gaming experience inspired by the popular game Counter-Strike. We understand the thrill and excitement that comes with engaging in immersive gameplay, and we aim to provide that same level of excitement to our NFT holders. The upcoming game will offer thrilling gameplay mechanics, exclusive features, and an immersive environment that will keep players hooked. Our commitment is to release a beta version of the game, ensuring that all NFT holders can have a memorable gaming experience and actively contribute to its development.
          </div>
        </div>

        <div class="intro-box">
          <div class="intro-text">
            <div class="Minor">GambleFi</div>
          </div>
          <div class="intro-desc">
          In addition to the game, CS PEPE has plans to launch its very own casino. This GambleFi platform will provide a wide range of exciting and entertaining options for our NFT holders. Get ready to indulge in various slot machines, try your luck at card games, and explore other thrilling casino offerings. We want to create an immersive and engaging environment where our community can enjoy endless opportunities for fun and winning.
          </div>
        </div>

        <div class="intro-box">
          <div class="intro-text">
            <div class="Minor">Revenue Share</div>
          </div>
          <div class="intro-desc">
          As part of our commitment to our community, we have developed a unique revenue-sharing model. We will utilize royalties earned from NFT trades, casino earnings, and airdrops from the BIG BANG competition to purchase CSGO skins for raffles among NFT holders. This initiative aims to not only reward our community members but also attract CSGO players into our vibrant and growing community. We believe that by fostering a sense of inclusivity and providing exciting opportunities, we can create a thriving ecosystem that benefits all.
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;