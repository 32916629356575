// AboutPage.js
import React from 'react';
// import "./App.css";
import "./globals.css";
import { Link } from 'react-router-dom';


function TestPage() {
  return (
    <div className='app'>
      <h1>MintPage Page</h1>
      <p>This is the About Page. Learn more about us here.</p>
      <Link to="/">Go back Home</Link>
    </div>
  );
}

export default TestPage;
